// import { Map } from 'immutable'

import Cookies from 'universal-cookie'
import lodashCollection from 'lodash/collection'
import lodashObject from 'lodash/object'
import lodashUtil from 'lodash/util'
import config from '../containers/LanguageSwitcher/config'
import {
  STATUS_GROUP_DOC_REPROCESS,
  STATUS_GROUP_INITIATED,
  STATUS_GROUP_REJECTED,
  STATUS_GROUP_SETTLED,
  agencyAgent,
  agencyCustomer,
  b2b2cUser,
  mapStatusGroupTextBackGround,
  mapStatusGroupTextCOLOR,
  tapinsuranceUser
} from '../constants'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import moment from 'moment'
import { LANG_STRINGS } from '../constants/lang'
import { capitalize } from 'lodash'
import { getCategoryIcon } from '../constants/categoryIcon'
import PasarpolisFooter from '../components/Footer'
import PPBrokerFooter from '../components/FooterPPBrokerV2'
import { ppBrokerLogo, ppLogo } from '../image'
import { COLORS } from '../constants/colors'

const cookies = new Cookies()

export function noCookiesHeader () {
  return { 'Content-Type': 'application/json', withCredentials: false }
}

export function preLoginHeader () {
  return { 'Content-Type': 'application/json', withCredentials: true }
}

export function getHeaderUploadFile () {
  // let token = getToken().get('token')
  return {
    'Content-Type': 'multipart/form-data',
    withCredentials: true
  }
}

export function getHeader () {
  // let token = getToken().get('token')
  return {
    'Content-Type': 'application/json',
    withCredentials: true
  }
}

export function clearToken () {
  let env = process.env.REACT_APP_ENV
  const hostname = window.location.hostname.split('.')[2]
  window.sessionStorage.removeItem('isFromGojek')
  window.sessionStorage.removeItem('id_token')
  window.sessionStorage.removeItem('isFromGojek')
  window.sessionStorage.removeItem('isFromGoDriver')
  window.sessionStorage.removeItem('isFromGolife')

  window.sessionStorage.removeItem('internal_id')
  const allCookies = cookies.getAll()
  const options = {
    path: '/'
  }
  const domainOption = {
    path: '/',
    domain: `.pasarpolis.${hostname}`
  }
  window.sessionStorage.removeItem(
    `pp_session_id${env === 'production' ? '' : `_${env}`}`,
    domainOption
  )

  window.sessionStorage.removeItem('orderId', options)
  window.sessionStorage.removeItem('isFromGojek', options)
  window.sessionStorage.removeItem('isFromGoDriver', options)
  window.sessionStorage.removeItem('isFromGolife', options)
  window.sessionStorage.clear()
  for (let key in allCookies) {
    cookies.remove(key, options)
  }
}

// export function getToken () {
//   let env = process.env.REACT_APP_ENV
//   try {
//     const token = cookies.get(`pp_session_id${env !== '' ? `_${env}` : ''}`)
//     return new Map({ token })
//   } catch (err) {
//     clearToken()
//     return new Map()
//   }
// }

export function timeDifference (givenTime) {
  givenTime = new Date(givenTime)
  const milliseconds = new Date().getTime() - givenTime.getTime()
  const numberEnding = (number) => {
    return number > 1 ? 's' : ''
  }
  const number = (num) => (num > 9 ? '' + num : '0' + num)
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000)
    const years = Math.floor(temp / 31536000)
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1)
      const day = number(givenTime.getUTCDate())
      const year = givenTime.getUTCFullYear() % 100
      return `${day}-${month}-${year}`
    }
    const days = Math.floor((temp %= 31536000) / 86400)
    if (days) {
      if (days < 28) {
        return days + ' day' + numberEnding(days)
      } else {
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
        const month = months[givenTime.getUTCMonth()]
        const day = number(givenTime.getUTCDate())
        return `${day} ${month}`
      }
    }
    const hours = Math.floor((temp %= 86400) / 3600)
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`
    }
    const minutes = Math.floor((temp %= 3600) / 60)
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`
    }
    return 'a few seconds ago'
  }
  return getTime()
}
export function envChecker (val) {
  switch (val) {
    case 'testing':
      return val.toUpperCase()
    case 'staging':
      return val.toUpperCase()
    default:
      return ''
  }
}

export const validatorCheck = (val1, val2, operator) => {
  val1 = parseInt(val1, 10)
  val2 = parseInt(val2, 10)
  switch (operator) {
    case 'eq':
      return val1 === val2
    case 'gte':
      return val1 >= val2
    case 'gt':
      return val1 > val2
    case 'lt':
      return val1 < val2
    case 'lte':
      return val1 <= val2
    default:
      return false
  }
}

export function millisecondToHMS (ms) {
  // 1- Convert to seconds:
  let seconds = ms / 1000
  seconds = seconds % 3600 // seconds remaining after extracting hours
  // 2- Extract minutes:
  let minutes = parseInt(seconds / 60, 10) // 60 seconds in 1 minute
  // 3- Keep only seconds not extracted to minutes:
  seconds = seconds % 60
  return `0${minutes}:${seconds > 9 ? '' : 0}${seconds}`
}

export function isMobileView () {
  var check = false;
  /* eslint-disable no-useless-escape */
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) { check = true }
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check && window.innerWidth < 768
}

export function getSelectedCountryCode () {
  // this function returns the country code
  // for selected language
  let selectedCountryCode = {}
  const lang = window.localStorage.getItem('lang')
  selectedCountryCode = lodashCollection.find(config.options, { locale: lang })
  return lodashObject.get(selectedCountryCode, 'countryCode', '62')
}

export function getYearRange (lastYear = 1989) {
  // this function returns the range of year from 2020 to 1990
  return lodashUtil.range(new Date().getFullYear(), lastYear)
}

export function convertSelectedImageBase64 (file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export function getQueryString (field, url) {
  var href = url || window.location.href
  var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i')
  var string = reg.exec(href)
  return string ? string[1] : null
}

export function returnNewObjectOnlyValidKeys (obj, validKeys) {
  const newObject = {}
  Object.keys(obj).forEach((key) => {
    if (validKeys.includes(key)) newObject[key] = obj[key]
  })
  return newObject
}

export function getExpiryDateByDays (days) {
  const today = new Date()
  const resultDate = new Date(today)
  resultDate.setDate(today.getDate() + days)
  return resultDate
}

export function formatCurrency (amount, currency) {
  // Check if the input is a valid number
  if (isNaN(amount)) {
    console.error('Invalid input. Please provide a valid number.')
    return null
  }

  // Convert the number to a string and add commas as thousands separator
  const formattedAmount = amount
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  // Return the formatted string with the 'Rp.' prefix
  return `${currency ? currency + '. ' : ''}${formattedAmount}`
}

export const isB2CUser = (serviceList = []) => {
  return serviceList.includes(b2b2cUser) || serviceList.includes(tapinsuranceUser)
}

export const isAgencyUser = (serviceList = []) => {
  return serviceList.includes(agencyAgent) || serviceList.includes(agencyCustomer)
}

export const useRemoveLoadingActionsHelper = (action = '') => {
  const loadingStates = useSelector(state => state.Loading)
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action)

  if (!matches) return

  const [, requestName] = matches
  return loadingStates[requestName]
}

export const removeActionStateAndReturnMatches = (action) => {
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(action)
  if (!matches) return
  const [, requestName] = matches
  return requestName
}

export const useRemoveErrorActionsHelper = (action = '') => {
  const errorStates = useSelector(state => state.Error)
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(action)

  if (!matches) return

  const [, requestName] = matches
  return errorStates[requestName]
}

export const sanitizeB2CPolicyListDetails = (policy, intl) => {
  const {
    ProductDisplayName = '',
    PackageName = '',
    ApplicationNo = '',
    ExpiresAt = {},
    Name,
    InternalID,
    // PackageCode: packageCode,
    ProductKey: product,
    Partner: partner,
    Request: request = {},
    policy_coverage_status: policyCoverageStatus = '',
    InsurancePolicyNo: insurancePolicyNo = '',
    PackageCodeSlug
  } = policy

  const endTime = get(ExpiresAt, 'Time', '')
  const endTimeValue = endTime ? intl.formatDate(moment(endTime), {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }) : ''
  const categoryCode = get(policy, 'Category.Code', '')
  const categoryName = get(policy, 'Category.Name', '')

  const logo = getCategoryIcon(categoryCode)

  const mainDetails = [
    { title: LANG_STRINGS.polisId, value: insurancePolicyNo || ApplicationNo },
    { title: LANG_STRINGS.categoryLabel, value: categoryName },
    { title: LANG_STRINGS.policyHolderLabel, value: Name },
    { title: LANG_STRINGS.end, value: endTimeValue }
  ]
  const additionalProtection = get(request, 'additional_protection', [])
  const isPolicyActive = policyCoverageStatus === 'active'
  const policyText = isPolicyActive ? intl.formatMessage({ id: LANG_STRINGS.activePolicy }) : intl.formatMessage({ id: LANG_STRINGS.policyExpired })
  const isFamilyPolicy = get(policy, 'Request.is_family', false)
  return {
    isFamilyPolicy,
    logo,
    policyText,
    packageCode: PackageCodeSlug,
    product,
    partner,
    isPolicyActive,
    additionalProtection,
    applicationNo: ApplicationNo,
    productDisplayName: ProductDisplayName,
    packageName: PackageName,
    internalId: InternalID,
    mainDetails
  }
}

export const sanitizeAgencyPolicyListDetails = (policy, intl) => {
  const additionalProtection = []
  const { app_no: appNumber, coverage_period: coveragePeriod = {}, name, reference_id: id } = policy
  const partnerLogoSquare = get(policy, 'provider_package.logo_url', '')
  const productDisplayName = (get(policy, 'provider_package.package.product.code', '').split('-').map((item) => capitalize(item)).join(' '))
  const packageName = get(policy, 'provider_package.package.name', '')
  const packageCode = get(policy, 'provider_package.package.code', '')
  const product = get(policy, 'provider_package.package.product.code', '')
  const insuranceprovider = get(policy, 'provider_package.provider.code', '')
  const coverageStatus = get(policy, 'coverage_period.status', '')
  const { end } = coveragePeriod
  const category = policy.insurance_type

  const endTimeValue = end ? intl.formatDate(moment(end), {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }) : ''

  const mainDetails = [
    { title: LANG_STRINGS.polisId, value: appNumber },
    { title: LANG_STRINGS.categoryLabel, value: category },
    { title: LANG_STRINGS.policyHolderLabel, value: name },
    { title: LANG_STRINGS.end, value: endTimeValue }
  ]
  const isPolicyActive = coverageStatus === 'ACTIVE'
  const policyText = isPolicyActive ? intl.formatMessage({ id: LANG_STRINGS.activePolicy }) : intl.formatMessage({ id: LANG_STRINGS.policyExpired })

  const logo = getCategoryIcon()

  const isFamilyPolicy = get(policy, 'request.is_family', false)
  return {
    isFamilyPolicy,
    logo,
    policyText,
    isPolicyActive,
    packageCode,
    product,
    insuranceprovider,
    applicationNo: appNumber,
    additionalProtection,
    packageName,
    partnerLogoSquare,
    productDisplayName,
    mainDetails,
    internalId: id
  }
}

export const sanitizeB2CClaimListDetails = (claim = {}, intl) => {
  const {
    product_key: productKey = '',
    policy_holder_name: name = 'name',
    benefit: benefitName,
    status_group: claimStatus,
    claim_no: claimId,
    claim_id: claimInternalId,
    created_at: createdAt,
    max_doc_submission_date: maxDocSubmissionDate = '',
    last_updated_date: claimSettledOn,
    category
  } = claim
  const claimStatusText = get(claim, 'status.display_name', '') || get(claim, 'status_group_external_display_name', '')
  const logo = getCategoryIcon(category)
  let footerText = ''
  let documentFillDate = ''

  const info = [
    {
      title: LANG_STRINGS.claimId,
      value: claimId
    }
  ]

  if ([
    STATUS_GROUP_INITIATED,
    STATUS_GROUP_DOC_REPROCESS
  ].includes(claimStatus)) {
    info.push({
      title: 'Klaim Dimulai',
      value: moment(createdAt, 'DD/MM/YYYY HH:mm:ss').isValid() ? intl.formatDate(moment(createdAt, 'DD/MM/YYYY HH:mm:ss'), {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }) : ''
    })
    footerText = LANG_STRINGS.submitDocument
    if ([STATUS_GROUP_INITIATED, STATUS_GROUP_DOC_REPROCESS].includes(claimStatus)) {
      documentFillDate = maxDocSubmissionDate && moment(maxDocSubmissionDate).format('DD/MM/YYYY,HH:mm:ss')
    }
  } else {
    info.push({
      title: 'Klaim Selesai',
      value: moment(claimSettledOn).isValid() ? intl.formatDate(moment(claimSettledOn), {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }) : ''
    })
    footerText = LANG_STRINGS.more
  }

  return {
    info,
    name,
    logo,
    benefitName,
    claimStatus,
    claimStatusText,
    footerText,
    documentFillDate,
    claimInternalId,
    claimStatusTextColor: mapStatusGroupTextCOLOR[claimStatus],
    claimStatusBackgroundColor: mapStatusGroupTextBackGround[claimStatus],
    productKey: productKey?.split('-').map((item) => capitalize(item)).join(' ')
  }
}

export const sanitizeAgencyClaimListDetails = (claim = {}, intl) => {
  const {
    product_key: productKey = '',
    policy_holder_name: name = 'name',
    benefit: benefitName,
    status_group: claimStatus,
    claim_no: claimId,
    claim_id: claimInternalId,
    created_at: createdAt,
    last_updated_date: claimSettledOn,
    max_doc_submission_date: maxDocSubmissionDate = '',
    category
  } = claim
  const claimStatusText = get(claim, 'status_group_external_display_name', '')
  const logo = getCategoryIcon(category)
  let footerText = ''
  let documentFillDate = ''

  const info = [
    {
      title: LANG_STRINGS.claimId,
      value: claimId
    }
  ]

  if (![
    STATUS_GROUP_REJECTED,
    STATUS_GROUP_SETTLED
  ].includes(claimStatus)) {
    info.push({
      title: 'Klaim Dimulai',
      value: moment(createdAt, 'DD/MM/YYYY HH:mm:ss').isValid() ? intl.formatDate(moment(createdAt, 'DD/MM/YYYY HH:mm:ss'), {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }) : ''
    })
    footerText = LANG_STRINGS.submitDocument
    documentFillDate = maxDocSubmissionDate
  } else {
    info.push({
      title: 'Klaim Selesai',
      value: moment(claimSettledOn).isValid() ? intl.formatDate(moment(claimSettledOn), {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }) : ''
    })
    footerText = LANG_STRINGS.more
  }

  return {
    logo,
    info,
    name,
    benefitName,
    claimStatus,
    claimStatusText,
    footerText,
    documentFillDate,
    claimInternalId,
    claimStatusTextColor: mapStatusGroupTextCOLOR[claimStatus],
    claimStatusBackgroundColor: mapStatusGroupTextBackGround[claimStatus],
    productKey: productKey?.split('-').map((item) => capitalize(item)).join(' ')
  }
}

export const sanitizeAgencyPolicyDetail = (policy = {}) => {
  const { ApplicationNo, InternalID, PackageCode, ProductKey, InsuranceProvider, PolicyType } = policy
  const additionalProtection = []
  const isFamilyPolicy = PolicyType === 'family'
  return {
    applicationNo: ApplicationNo,
    internalId: InternalID,
    packageCode: PackageCode,
    product: ProductKey,
    additionalProtection,
    insuranceprovider: InsuranceProvider,
    isFamilyPolicy
  }
}

export const sanitizeB2cPolicyDetail = (policy = {}) => {
  const { ApplicationNo, InternalID, PackageCodeSlug, ProductKey, InsuranceProvider } = policy
  const additionalProtection = get(policy, 'Request.additional_protection', false)
  const isFamilyPolicy = get(policy, 'Request.is_family', false)
  return {
    applicationNo: ApplicationNo,
    internalId: InternalID,
    packageCode: PackageCodeSlug,
    product: ProductKey,
    additionalProtection,
    insuranceprovider: InsuranceProvider,
    isFamilyPolicy
  }
}

export const ppBrokerAssets = {
  logoUrl: ppBrokerLogo,
  footer: <PPBrokerFooter />,
  googleMapLink: 'https://maps.app.goo.gl/EwyEu6YHzztWsfT59',
  headerBackgroundColor: COLORS.darkYellow,
  landingPageText: 'Nikmati kemudahan dalam pengajuan klaim, cek status klaim dan polis Anda melalui <highlight>Portal Klaim</highlight> Pasarpolis',
  landingPageTextHighlightColor: COLORS.darkYellow,
  phoneNumber1: '+62 (21) 508 606 57',
  phoneNumber2: '+62 (21) 311 181 77',
  emailAddress: 'cs@ppibroker.com',
  showAvaPopup: false
}

export const pasarpolisAssets = {
  logoUrl: ppLogo,
  footer: <PasarpolisFooter />,
  googleMapLink: '',
  headerBackgroundColor: COLORS.brandYellow,
  landingPageText: 'Nikmati kemudahan dalam pengajuan klaim, cek status klaim dan polis Anda melalui <highlight>Portal Klaim</highlight> atau chat dengan <highlight>Poli</highlight>.',
  landingPageTextHighlightColor: COLORS.originGold,
  showAvaPopup: true
}

export const getAssets = () => {
  if (window.location.hostname.includes('ppibroker')) return ppBrokerAssets
  return pasarpolisAssets
}
