const host = process.env.REACT_APP_URL
const BASE_PATH = 'api/v1/agency/'

const agencyPolicyDetail = () => {
  return `${host}${BASE_PATH}policy-details/?er=1`
}

const validateAgencyClaimUrl = () => {
  return `${host}${BASE_PATH}validate-claim/`
}

const postAgencyClaim = () => {
  return `${host}${BASE_PATH}create-claim/`
}

const agencyBenefitsURL = payload => {
  return `${host}${BASE_PATH}benefit-list/?package_code=${payload.package}&product_key=${payload.product}&insurance_provider=${payload.insurance_provider}`
}

const agencyPolicyListURL = (payload = {}) => {
  const { query = {} } = payload
  const coverageStatusParam = query.coverageStatus ? `&coverage_status=${query.coverageStatus}` : ''
  const page = query.page ? `&page=${query.page}` : ''
  let productCodesQuery = '&product_code=car-protection&product_code=travel-protection'
  const status = `status=COMPLETED`
  return `${host}${BASE_PATH}policy-list?${status}${productCodesQuery}${coverageStatusParam}${page}&page_size=${query.pageSize}`
}
const agencyClaimListURL = payload => {
  return `${host}${BASE_PATH}claim-list`
}

export default {
  agencyPolicyDetail,
  validateAgencyClaimUrl,
  postAgencyClaim,
  agencyBenefitsURL,
  agencyPolicyListURL,
  agencyClaimListURL
}
